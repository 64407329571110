import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'


import Access from 'containers/Access'

function withAuthRouter(WrappedComponent = {}, { disableLoading = false } = {}) {
    const ConnectedAuthWrapper = connect((state, props) => {
        return {
            ...props,
            disableLoading,
            accessToken: state._network.access_token['default'],
            isAuthed: state.auth.isAuthed,
            publisherId: state.account.publisherId,
            refreshToken: state._network.refresh_token['default'],
            scope: state._network.scope,
            stripe: state.integrations.stripe || state.integrations.stripeExpress,
            integrationsLoading: state.integrations.loading,
        }
    })(AuthWrapper)

    return withRouter((props) => (
        <ConnectedAuthWrapper {...props}>
            <WrappedComponent />
        </ConnectedAuthWrapper>
    ))
}

class AuthWrapper extends React.PureComponent {

    render() {
        const {
            disableLoading,
            router,
            children,
            dispatch,
        } = this.props

        let {
            accessToken,
            refreshToken,
        } = this.props

        // const { query } = router
        // const { pico_ui } = query

        const scope = router.query.scope || this.props.scope

        // const passwordResetExpiration = pico_ui === 'passwordResetExpiration'
        // const emailLinkExpiration = pico_ui === 'emailLinkExpiration'
        if (router.query.refresh_token) {
            accessToken = null
            refreshToken = router.query.refresh_token
        }

        return (
            <Access accessToken={accessToken} refreshToken={refreshToken} router={router} disableLoading={disableLoading} scope={scope}>
                {React.cloneElement(children, {
                    ...this.props,
                    accessToken,
                    refreshToken,
                    router,
                    dispatch,
                })}
            </Access>
        )
    }
}

AuthWrapper.defaultProps = {
    publisherId: null
}

AuthWrapper.propTypes = {
    accessToken: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
    children: PropTypes.node,
    disableLoading: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    isAuthed: PropTypes.bool,
    publisherId: PropTypes.string,
    refreshToken: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
    router: PropTypes.object.isRequired,
    scope: PropTypes.string
}

export default withAuthRouter
