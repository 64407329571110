import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Loading } from '@piconetworks/ui'
import AuthModule from 'modules/auth'
import AccountModule from 'modules/account'
import { redeemClaim } from 'features/platformAccess'

class Access extends React.Component {
    componentDidMount() {
        const {
            accessToken, refreshToken, router, dispatch,
        } = this.props

        const scope = router.query.scope || this.props.scope
        const { refresh_token, publisher_id } = router.query
        if (router.query.claim) {
            dispatch(AuthModule.creators.claimMagicLink({ token_claim: router.query.claim, scope }))
        } else if (router.query._claim) {
            dispatch(redeemClaim({
                claim: router.query._claim,
                ...router.query.redirectTo && { redirectTo: router.query.redirectTo },
            }))
        } else if (refresh_token && publisher_id) {
            dispatch(AuthModule.creators.loginFromOnboarding({ refresh_token, publisher_id, redirectTo: router?.query?.redirectTo }))
        } else if (!accessToken && refreshToken) {
            dispatch(AuthModule.creators.useRefreshToken())
        } else if (!accessToken && !refreshToken) {
            router.push('/logout')
        }
    }

    componentDidUpdate(prevProps) {
        const { dispatch, publisherId } = this.props
        if (!prevProps.publisherId && publisherId) {
            dispatch(AuthModule.creators.postLogin())
        }
    }

    render() {
        const {
            accessToken, refreshToken, router, disableLoading, children, claim, isAuthed
        } = this.props
        
        if (router?.query?.redirectTo) {
            return <Loading />
        }

        const magicLinkClaimInProgress = claim && !isAuthed

        if (!disableLoading
            && (
                (router.query.claim && !refreshToken)
                || (!accessToken && refreshToken)
                || magicLinkClaimInProgress
            )
        ) {
            return <Loading />
        }

        if (!accessToken && !refreshToken) {
            return <Loading />
        }

        return React.cloneElement(children, {
            accessToken,
            refreshToken
        })
    }
}

const mapStateToProps = (state) => ({
    claim: state.auth.claim,
    isAuthed: state.auth.isAuthed,
    publisherId: AccountModule.selectors.getPublisherId(state),
})

Access.defaultProps = {
    accessToken: null,
}

Access.propTypes = {
    accessToken: PropTypes.string,
    children: PropTypes.node,
    claim: PropTypes.string,
    disableLoading: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    isAuthed: PropTypes.bool,
    refreshToken: PropTypes.string,
    router: PropTypes.shape({
        pathname: PropTypes.string,
        push: PropTypes.func,
        query: PropTypes.shape({
            _claim: PropTypes.string,
            claim: PropTypes.string,
            id: PropTypes.string,
            publisher_id: PropTypes.string,
            redirectTo: PropTypes.string,
            refresh_token: PropTypes.string,
            scope: PropTypes.string,
            short_code: PropTypes.string,
        }),
    }).isRequired,
    scope: PropTypes.string,
}

export default connect(mapStateToProps)(Access)
