import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import withAuthRouter from 'containers/AuthRouter'

import Auth from 'modules/auth'

import LoggedInScaffolding from 'containers/LoggedInScaffolding'

const IndexPage = ({ router }) => {
    const loading = useSelector(Auth.selectors.getPostLoginLoading)

    return !loading && (
        <LoggedInScaffolding
            pageTitle='Welcome'
            initialLoading router={router}
        />
    )
}

IndexPage.propTypes = {
    router: PropTypes.object
}

export default withAuthRouter(IndexPage)
